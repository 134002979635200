var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',{staticClass:"pa-4 d-flex flex-nowrap",attrs:{"justify":"space-between"}},[_c('v-col',{staticStyle:{"border-right":"1px solid grey"},attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Search for Team","append-icon":"mdi-magnify"},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.teamSearchString),callback:function ($$v) {_vm.teamSearchString=$$v},expression:"teamSearchString"}})],1)],1),_c('v-treeview',{attrs:{"active":_vm.selectedTeamId,"items":_vm.filteredTeams,"item-text":"name","color":"primary","activatable":""},on:{"update:active":function($event){_vm.selectedTeamId=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-group-outline")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.loggedInUserStakeholderId == item.id)?_c('v-icon',[_vm._v(" mdi-crown ")]):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(_vm.filteredTeams.length === 0 && _vm.teamSearchString)?_c('span',[_vm._v(" No teams found with the search term. ")]):(!_vm.selectedTeam)?_c('span',[_vm._v(" Select a team to see more details. ")]):_c('v-card',{key:_vm.selectedTeam[0].id,staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"py-4",attrs:{"cols":"8"}},[_c('h3',{staticClass:"headline"},[_vm._v(_vm._s(_vm.selectedTeam[0].name))])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[(_vm.$store.state.Permissions.TeamUpdate)?_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":_vm.handleEditTeam}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1),_c('v-divider',{staticClass:"my-4"}),(_vm.users.length > 0)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pa-4 text-left",attrs:{"cols":"6"}},[_c('v-icon',[_vm._v("mdi-account-multiple")]),_c('span',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.users.length)+" Users in team")])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleAddMember}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Team Members ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","clearable":"","label":"Search Users","single-line":"","outlined":"","hide-details":""},on:{"click:clear":function($event){_vm.userSearch = ''}},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredUsers},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-2"},[_c('v-avatar',{attrs:{"size":"85"}},[_c('img',{attrs:{"src":_vm.getUserAvatar(item.contactCard.profilePicture),"alt":"User Avatar"}})])],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(item.user ? 'true' : 'false')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRemoveMember(item)}}},[_c('v-icon',{staticStyle:{"font-size":"25px"},attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline")])],1)]}}],null,false,59465623)})],1)],1)],1):_c('div',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('span',[_vm._v("There doesn't seem to be any contacts here yet.")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleAddMember}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Team Members ")],1)],1)],1)],1)],1)],1)],1)],1),_c('RemoveUserDialog',{attrs:{"user":_vm.selectedUserForDelete,"team":_vm.selectedTeamForDelete},on:{"confirm":_vm.removeUserFromTeam,"cancel":_vm.clearRemoveDialog},model:{value:(_vm.removeUserFromTeamDialog),callback:function ($$v) {_vm.removeUserFromTeamDialog=$$v},expression:"removeUserFromTeamDialog"}}),_c('CreateUserDialog',{attrs:{"roles":_vm.roles,"selected-role":_vm.selectedRole},on:{"create-user":_vm.createUserAccForNewContactCard,"cancel":_vm.clearCreateUserDialog},model:{value:(_vm.promptCreateUserDialog),callback:function ($$v) {_vm.promptCreateUserDialog=$$v},expression:"promptCreateUserDialog"}}),_c('AddTeamMemberDialog',{attrs:{"contact-cards":_vm.contactCards,"headers":_vm.headers,"current-team-users":_vm.users},on:{"add-member":_vm.decideAddOrPromptCreateUser},model:{value:(_vm.addTeamMemberDialog),callback:function ($$v) {_vm.addTeamMemberDialog=$$v},expression:"addTeamMemberDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }