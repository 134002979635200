<!-- TreeView.vue -->
<template>
  <v-card>
    <v-row justify="space-between" class="pa-4 d-flex flex-nowrap">
      <!-- Left Sidebar -->
      <v-col cols="3" style="border-right: 1px solid grey">
        <v-row>
          <v-col cols="12" class="py-0 my-0">
            <v-text-field
              v-model="teamSearchString"
              outlined
              label="Search for Team"
              append-icon="mdi-magnify"
              @click:clear="clearSearch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-treeview
          :active.sync="selectedTeamId"
          :items="filteredTeams"
          item-text="name"
          color="primary"
          activatable
        >
          <template v-slot:prepend="{ item }">
            <v-icon>mdi-account-group-outline</v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-icon v-if="loggedInUserStakeholderId == item.id">
              mdi-crown
            </v-icon>
          </template>
        </v-treeview>
      </v-col>

      <!-- Main Content -->
      <v-col cols="9">
        <v-scroll-y-transition mode="out-in">
          <span v-if="filteredTeams.length === 0 && teamSearchString">
            No teams found with the search term.
          </span>
          <span v-else-if="!selectedTeam">
            Select a team to see more details.
          </span>
          <v-card v-else :key="selectedTeam[0].id" class="mx-auto" flat>
            <!-- Team Header -->
            <v-row>
              <v-col cols="8" class="py-4">
                <h3 class="headline">{{ selectedTeam[0].name }}</h3>
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn
                  v-if="$store.state.Permissions.TeamUpdate"
                  fab
                  small
                  color="primary"
                  @click="handleEditTeam"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <!-- Team Members Section -->
            <div v-if="users.length > 0">
              <v-row>
                <v-col cols="6" class="pa-4 text-left">
                  <v-icon>mdi-account-multiple</v-icon>
                  <span class="text-h4"> {{ users.length }} Users in team</span>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn color="primary" @click="handleAddMember">
                    <v-icon>mdi-plus</v-icon>Team Members
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Users Table -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="userSearch"
                    append-icon="mdi-magnify"
                    clearable
                    label="Search Users"
                    single-line
                    outlined
                    hide-details
                    class="mb-4"
                    @click:clear="userSearch = ''"
                  />
                  <v-data-table :headers="headers" :items="filteredUsers">
                    <template v-slot:item.image="{ item }">
                      <div class="ma-2">
                        <v-avatar size="85">
                          <img
                            :src="getUserAvatar(item.contactCard.profilePicture)"
                            alt="User Avatar"
                          >
                        </v-avatar>
                      </div>
                    </template>
                    <template v-slot:item.user="{ item }">
                      <div class="ma-2">
                        {{ item.user ? 'true' : 'false' }}
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        icon
                        color="primary"
                        @click="handleRemoveMember(item)"
                      >
                        <v-icon color="red" style="font-size: 25px">mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>

            <!-- Empty State -->
            <div v-else>
              <v-row>
                <v-col cols="6" class="text-left">
                  <span>There doesn't seem to be any contacts here yet.</span>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn color="primary" @click="handleAddMember">
                    <v-icon>mdi-plus</v-icon>Team Members
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <RemoveUserDialog
      v-model="removeUserFromTeamDialog"
      :user="selectedUserForDelete"
      :team="selectedTeamForDelete"
      @confirm="removeUserFromTeam"
      @cancel="clearRemoveDialog"
    />

    <CreateUserDialog
      v-model="promptCreateUserDialog"
      :roles="roles"
      :selected-role="selectedRole"
      @create-user="createUserAccForNewContactCard"
      @cancel="clearCreateUserDialog"
    />

    <AddTeamMemberDialog
    v-model="addTeamMemberDialog"
    :contact-cards="contactCards"
    :headers="headers"
    :current-team-users="users"
    @add-member="decideAddOrPromptCreateUser"
  />
  </v-card>
</template>

<script>
  import StakeholderController from '@/services/controllers/Stakeholder'
  import UserController from '@/services/controllers/User'
  import RemoveUserDialog from './dialogs/RemoveUserDialog.vue'
import CreateUserDialog from './dialogs/CreateUserDialog.vue'
import AddTeamMemberDialog from './dialogs/AddTeamMemberDialog.vue'

export default {
  name: 'TreeView',
  
  components: {
    RemoveUserDialog,
    CreateUserDialog,
    AddTeamMemberDialog,
  },

  props: {
    teams: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      userSearch: '',
      selectedTeamId: [],
      selectedTeam: null,
      users: [],
      contactCards: [],
      removeUserFromTeamDialog: false,
      selectedUserForDelete: null,
      selectedTeamForDelete: null,
      addTeamMemberDialog: false,
      selectedTeamForAdd: null,
      filteredTeams: [],
      editTeamDialog: false,
      promptCreateUserDialog: false,
      teamSearchString: null,
      loggedInUserStakeholderId: null,
      selectedRole: null,
      roles: [],
      headers: [
        { sortable: true, value: 'image' },
        { sortable: true, text: 'Forename', value: 'contactCard.firstName' },
        { sortable: true, text: 'Surname', value: 'contactCard.surname' },
        { sortable: true, text: 'Title', value: 'contactCard.jobTitle' },
        { sortable: false, text: 'Email', value: 'contactCard.email' },
        { sortable: true, text: 'Company', value: 'contactCard.stakeholder.friendlyName' },
        { sortable: false, text: 'Actions', value: 'actions' },
      ],
      searchDebounceTimeout: null,
    }
  },

  watch: {
    selectedTeamId(teamId) {
      if (teamId?.length) {
        this.handleTeamSelection(teamId[0])
      } else {
        this.clearTeamSelection()
      }
    },
    teams: {
      immediate: true,
      handler(newTeams) {
        if (newTeams?.length) {
          this.filteredTeams = newTeams
        }
      },
    },
    teamSearchString(newVal) {
      this.debounceSearch(newVal)
    },
  },

  mounted() {
    this.initialize()
  },

  computed: {
  filteredUsers() {
    if (!this.userSearch) {
      return this.users;
    }
    
    const searchTerm = this.userSearch.toLowerCase();
    return this.users.filter(user => {
      const contactCard = user.contactCard;
      return (
        (contactCard.firstName && contactCard.firstName.toLowerCase().includes(searchTerm)) ||
        (contactCard.surname && contactCard.surname.toLowerCase().includes(searchTerm)) ||
        (contactCard.jobTitle && contactCard.jobTitle.toLowerCase().includes(searchTerm)) ||
        (contactCard.email && contactCard.email.toLowerCase().includes(searchTerm)) ||
        (contactCard.stakeholder && contactCard.stakeholder.friendlyName.toLowerCase().includes(searchTerm))
      );
    });
  }
},

  methods: {
    async initialize() {
      await Promise.all([
        this.getAllContactCards(),
        this.getRoles(),
      ])
    },

    async handleTeamSelection(teamId) {
      this.selectedTeam = this.teams.filter(team => team.id === teamId)
      await this.getAllTeamUsers(teamId)
    },

    clearTeamSelection() {
      this.users = []
      this.selectedTeam = null
    },

    debounceSearch(value) {
      if (this.searchDebounceTimeout) {
        clearTimeout(this.searchDebounceTimeout)
      }
      this.searchDebounceTimeout = setTimeout(() => {
        this.filteredTeams = this.teams.filter(team =>
          team.name.toLowerCase().includes(value.toLowerCase())
        )
      }, 300)
    },

    async getAllTeamUsers(teamId) {
      try {
        const response = await UserController.getTeamUsers(teamId)
        this.users = response.data
      } catch (error) {
        this.$root.$emit('snackbarError', error.response?.data?.message || 'Error fetching team users')
      }
    },

    async getAllContactCards() {
      try {
        const response = await UserController.getUsers()
        this.contactCards = response.data
      } catch (error) {
        this.$root.$emit('snackbarError', error.response?.data?.message || 'Error fetching contact cards')
      }
    },

    async getRoles() {
      try {
        const response = await StakeholderController.getPrimaryRoles()
        this.roles = response.data
      } catch (error) {
        this.$root.$emit('snackbarError', error.response?.data?.message || 'Error fetching roles')
      }
    },

    clearSearch() {
      this.teamSearchString = null
    },

    handleEditTeam() {
      this.editTeamDialog = true
      this.teamToEdit = this.selectedTeam[0]
    },

    handleAddMember() {
    this.selectedUsers = []; // Clear any previously selected users
    this.addTeamMemberDialog = true;
    this.selectedTeamForAdd = this.selectedTeam[0];
  },

    handleRemoveMember(user) {
      this.removeUserFromTeamDialog = true
      this.selectedUserForDelete = user
      this.selectedTeamForDelete = this.selectedTeam[0]
    },

    async removeUserFromTeam() {
      try {
        await UserController.removeUserFromTeam(
          this.selectedTeamForDelete.id,
          this.selectedUserForDelete.id
        )
        this.$root.$emit('snackbarSuccess', 'User removed from team successfully')
        this.removeUserFromTeamDialog = false
        await this.getAllTeamUsers(this.selectedTeamForDelete.id)
      } catch (error) {
        this.$root.$emit('snackbarError', error.response?.data?.message || 'Error removing user')
      }
    },

    decideAddOrPromptCreateUser(contactCards) { // Now accepts an array
    contactCards.forEach(contactCard => {
      if (contactCard.user) {
        this.addUserToTeam(contactCard.user);
      } else {
        this.contactCardIdForAdd = contactCard.contactCard.id;
        this.promptCreateUserDialog = true;
      }
    });
  },

    async createUserAccForNewContactCard() {
      try {
        const response = await UserController.createUserFromContactCard({
          contactCardId: this.contactCardIdForAdd,
          roleId: this.selectedRole,
        })
        this.$root.$emit('snackbarSuccess', 'User account created successfully')
        await this.addUserToTeam(response.data)
      } catch (error) {
        this.$root.$emit('snackbarError', error.response?.data?.message || 'Error creating user')
      } finally {
        this.clearCreateUserDialog()
      }
    },

    async addUserToTeam(user) {
      try {
        await UserController.addUserToTeam({
          userId: user.id,
          teamId: this.selectedTeamForAdd.id,
        })
        this.$root.$emit('snackbarSuccess', 'User added to team successfully')
        this.addTeamMemberDialog = false
        await this.getAllTeamUsers(this.selectedTeamForAdd.id)
      } catch (error) {
        this.$root.$emit('snackbarError', error.response?.data?.message || 'Error adding user to team')
      }
    },

    clearRemoveDialog() {
      this.removeUserFromTeamDialog = false
      this.selectedUserForDelete = null
      this.selectedTeamForDelete = null
    },

    clearCreateUserDialog() {
      this.promptCreateUserDialog = false
      this.contactCardIdForAdd = null
      this.selectedRole = null
    },

    getUserAvatar(profilePicture) {
      return profilePicture?.urlThumb || 'contact-placeholder.jpg'
    },
  },
}
</script>

<style scoped>
.v-card {
  height: 100%;
}
</style>
